<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Price lists
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <PriceListModalForm
            :loader="loader"
            :modalData="modalData"
            :statuses="statuses"
            :permissions="permissions"
            :currencyCollection="currencyCollection"
            :itemCollection="itemCollection"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></PriceListModalForm>
        </v-col>
      </v-row>

      <AlertComponent
        :alertShow="alert.show"
        :alertText="alert.text"
        @closeAlert="alert.show = false"
      ></AlertComponent>
      <v-data-table
        :headers="headers"
        :items="collection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
      >
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="permissionCan('delete') && item.deletable"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import AlertComponent from "@/view/components/AlertComponent";
import i18nService from "@/core/services/i18n.service.js";
import PriceListModalForm, { initialFormData } from "./PriceListModalForm";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const ENDPOINT = "inventory/priceList/";

export default {
  name: "PriceLists",
  components: { PriceListModalForm, AlertComponent },
  data() {
    return {
      loader: false,
      showAll: false,
      search: "",
      collection: [],
      permissions: [],
      alert: {
        show: false,
        text: "You can't delete a price list that contains items",
      },
      headers: [
        {
          text: "Price List name",
          value: "name",
        },
        {
          text: "Default tax name",
          value: "vat_name",
        },
        {
          text: "Default tax rate",
          value: "vat_value",
        },
        {
          text: "Currency",
          value: "currency_id",
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedIndex: -1,
        editedItem: Object.assign({}, initialFormData()),
      },

      dialogDelete: false,
      snackbar: false,
    };
  },

  computed: {
    ...mapGetters([
      "priceListCollection",
      "statuses",
      "currencyCollection",
      "itemCollection",
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // showAll() {
    //   if (this.companyTypeCollection.length > 0) {
    //     this.filterCollection(this[DEPARTMENT_COLLECTION]);
    //   }
    // },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    priceListCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions([
      "fetchCurrency",
      "fetchPriceList",
      "fetchItem",
      "fetchQuantityUnit",
    ]),
    editItem(id) {
      this.loader = true;
      this.modalData.dialog = true;
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          this.modalData.editedIndex = 1;
          let appLocale = i18nService.getActiveLanguage();
          data.items = data.items.map((item) => {
            if (item.translations.length == 0) {
              item.name = "Name not defined";
              return item;
            }
            if (item.translations[appLocale]) {
              item.name = item.translations[appLocale].name;
            } else {
              item.name =
                item.translations[Object.keys(item.translations)[0]].name;
            }

            let qu = this.$store.getters.getQuantityUnitByID(
              item.quantity_unit_id
            );

            if (typeof qu == "object") {
              item.quantityUnitTranslated = qu.name;
            }

            item.item = { ...this.$store.getters.getItemByID(item.item_id) };

            return item;
          });

          this.modalData.editedItem = Object.assign({}, data);

          this.modalData.dialog = true;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    deleteItem(item) {
      this.editedIndex = this.priceListCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchPriceList();
          this.fetchCurrency();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;

      this.modalData.editedItem = Object.assign({}, initialFormData());
      if (this.$route.name !== "pricelists")
        this.$router.push({ name: "pricelists" });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      /**Delete unused translations */
      // i18nService.languages.forEach((item) => {
      //   let langCode = item.lang;
      //   if (
      //     model.translations[langCode] &&
      //     model.translations[langCode].name == ""
      //   ) {
      //     delete model.translations[langCode];
      //   }
      // });
      /**Delete unused translations */

      // if (this.modalData.editedIndex > -1) {
      this.loader = true;
      if (model.id) {
        ApiService.put(ENDPOINT + model.id, model)
          .then(() => {
            this.snackbar = true;
            this.fetchPriceList();
            // this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        //create model
        ApiService.post(ENDPOINT, model)
          .then(({ data }) => {
            this.snackbar = true;
            this.fetchPriceList();
            this.handleClickItem(data);
            // this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/inventory/pricelists/${item.id}`);
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "inventory.priceList." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("priceList") > -1
        );
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Price list" }]);
    this.fetchCurrency();
    this.fetchPriceList();
    this.fetchItem();
    this.setPermissions();
    this.fetchQuantityUnit();
  },
};
</script>
